import * as React from 'react';

import {
	Box,
	Button,
	Center,
	HStack,
	SimpleGrid,
	Stack,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { FunctionComponent, useMemo, useState } from 'react';

import { BLOG_SORT_ITEMS } from '../constants/blogConstants';
import BlogFilter from '../components/BlogFilter';
import BlogHeroIntro from '../components/blog-hero-intro';
import HeroContainer from '../components/Containers/HeroContainer';
import { IoClose } from 'react-icons/io5';
import Layout from '../components/Base/Layout';
import MobileBlogFilter from '../components/BlogFilter/MobileBlogFilter';
import { PageProps } from 'gatsby';
import Pagination from '../components/Shop/Pagination';
import { SEO } from '../components/Base/Seo';
import SortBy from '../components/SortBy';
import { fetchAllBlogCategories } from '../queries/fetchAllBlogCategories';
import { graphql } from 'gatsby';
import { useBlogs } from '../hooks/blogHooks';

const Blog: FunctionComponent<PageProps> = ({ data, location }: any) => {
	const posts = data.allWpPost.nodes;

	const {
		numberOfPages,
		currentPage,
		prevPageHandler,
		nextPageHandler,
		goToPage,
		onSortSelectionChange,
		sortBy,
		filteredItems,
		categories,
		selectedCategories,
		setSelectedCategories,
	} = useBlogs({
		items: posts,
		location: location,
	});

	const allCategories = fetchAllBlogCategories();

	const params = new URLSearchParams(location.search);
	const pageParam = params.get('page');

	const allPostsPerPage = 12;
	const [postsPerPage, setPostsPerPage] = useState(allPostsPerPage);
	const [input, setInput] = useState(Number(pageParam) || 1);

	const sortedPosts = useMemo(() => {
		const pinnedPosts = posts.filter((post: any) => post.pinnedPosts.pinned);
		const regularPosts = posts.filter((post: any) => !post.pinnedPosts.pinned);
		return [...pinnedPosts, ...regularPosts];
	}, [posts]);

	const [isMobile] = useMediaQuery('(max-width: 768px)');

	const visiblePosts = useMemo(() => {
		const startIndex = (currentPage - 1) * postsPerPage;
		const endIndex = startIndex + postsPerPage;
		return sortedPosts.slice(startIndex, endIndex);
	}, [sortedPosts, currentPage, postsPerPage]);

	const categoryCounts: { [slug: string]: number } = {};
	posts.forEach((post: any) => {
		post.categories.nodes.forEach((category: any) => {
			const slug = category.slug;
			// Use category name as key for categoryCounts
			const categoryName = category.name.toLowerCase(); // Convert to lowercase for consistency
			categoryCounts[categoryName] = (categoryCounts[categoryName] || 0) + 1;
		});
	});

	const showSelectedCategory = () => {
		return selectedCategories.map((category, index) => (
			<Button
				key={index}
				h='24px'
				ml={{ base: 0, md: 2 }}
				variant='outline'
				borderRadius='full'
				border='none'
				color='#B97230'
				bg='#FEF1E5'
				fontSize='md'
				fontWeight='400'
				_hover={{ bg: 'orange.100' }}
				name='close'
				cursor='pointer'
				textTransform='capitalize'
				rightIcon={<IoClose />}
				onClick={() => {
					setSelectedCategories((prevCategories) =>
						prevCategories.filter((cat) => cat !== category)
					);
				}}>
				{category}
			</Button>
		));
	};

	const onClearFilters = () => {
		setSelectedCategories([]);
	};

	return (
		<>
			<Layout>
				<HeroContainer
					height='395px'
					backgroundPosition='right'
					is404={false}
					heroImage='/dm-blog-hero.webp'
					heroTitle='Beauty News'
					heroSubTitle='Find industry news, trends, and innovative products right here in our blog.'
					arrowImage='/remixiconslinesystemarrowdownsline2.svg'
				/>
				<>
					<Box>
						<HStack
							px={{ base: 4, '2xl': 12 }}
							py={5}
							justifyContent='space-between'
							alignItems='center'
							borderBottom='1px solid rgba(0, 0, 0, 0.16)'>
							<Stack
								flexFlow={{ base: 'column', md: 'row' }}
								alignItems={{ base: 'flex-start', md: 'center' }}
								justifyContent='center'
								gap={0}>
								<Text
									color='#120B0C'
									fontSize={{ base: '24px', md: '36px' }}
									fontWeight={400}>
									Latest articles
								</Text>
								<HStack>
									<Text mt={2}>{showSelectedCategory()}</Text>
								</HStack>
							</Stack>
							{isMobile && (
								<MobileBlogFilter
									categories={categories}
									selectedCategories={selectedCategories}
									setSelectedCategories={setSelectedCategories}
									filteredItems={visiblePosts}
									categoryCounts={categoryCounts}
									items={BLOG_SORT_ITEMS}
									selectedSortItem={BLOG_SORT_ITEMS.find(
										(item) => item.value === sortBy
									)}
									onSortSelectionChange={onSortSelectionChange}
									onClearFilters={onClearFilters}
								/>
							)}

							<Stack display={{ base: 'none', lg: 'flex' }}>
								<SortBy
									fontSize='md'
									selectedItem={BLOG_SORT_ITEMS.find(
										(item) => item.value === sortBy
									)}
									items={BLOG_SORT_ITEMS}
									onItemChange={onSortSelectionChange}
								/>
							</Stack>
						</HStack>
						<Box bg={'#F9F9F9'}>
							<Stack
								w='100%'
								px={2}
								flexDir='row'
								justifyContent={{ md: 'space-between', lg: 'center' }}>
								<Box
									display={{ lg: 'flex', base: 'none' }}
									bg='white'>
									<Stack
										w='324px'
										direction='column'
										spacing={4}
										borderRight={'1px solid rgba(0, 0, 0, 0.16)'}
										borderBottom={'1px solid rgba(0, 0, 0, 0.16)'}>
										<Text
											px={2}
											pt={5}
											pb={3}
											fontWeight='400'
											fontSize='md'
											lineHeight='24px'
											color='#120B0C'>
											Categories
										</Text>
										<BlogFilter
											categories={categories}
											selectedCategories={selectedCategories}
											setSelectedCategories={setSelectedCategories}
											filteredItems={filteredItems}
											categoryCounts={categoryCounts}
										/>
									</Stack>
								</Box>

								<SimpleGrid
									id='anchorlink'
									h='fit-content'
									pt={2}
									maxW='100%'
									bg={'#F9F9F9'}
									columns={{ base: 1, md: 2, lg: 3 }}
									gap={{ base: '18px', lg: '18px' }}>
									{filteredItems.map((item: any) => (
										<BlogHeroIntro
											authorSlug={item.author?.node?.slug}
											link={'/blog/' + item.slug}
											blogImgThumb={item.featuredImage?.node?.sourceUrl}
											category={item.categories.nodes.map((item: any) =>
												item.name.toUpperCase()
											)}
											postTitle={item.title}
											excerpt={item.seo.metaDesc}
											author={item.author?.node?.name}
											date={item.date}
										/>
									))}
								</SimpleGrid>
							</Stack>
							<Center pt='24px'>
								<Center pt='24px'>
									<Pagination
										numberOfPages={numberOfPages}
										currentPage={currentPage}
										prevPageHandler={prevPageHandler}
										nextPageHandler={nextPageHandler}
										goToPage={goToPage}
										baseUrl={`blog`}
									/>
								</Center>
							</Center>
						</Box>
					</Box>
				</>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	query BlogNews {
		wp {
			readingSettings {
				postsPerPage
			}
		}
		allWpCategory {
			nodes {
				name
				slug
			}
		}
		allWpPost {
			nodes {
				seo {
					metaDesc
				}
				pinnedPosts {
					pinned
				}
				id
				link
				slug
				excerpt
				uri
				content
				title
				excerpt
				date(formatString: "DD  MMM  YYYY")
				author {
					node {
						name
						slug
					}
				}
				categories {
					nodes {
						name
					}
				}
				featuredImage {
					node {
						sourceUrl
						uri
					}
				}
			}
		}
	}
`;

export const Head = () => (
	<SEO
		title='Medical News Covering Aesthetics, Orthopedics & More'
		description='Read news you can use in your medical practice, from innovative cosmetic surgery techniques to anti-aging breakthroughs to the newest uses for Botox.'
	/>
);

export default Blog;
